import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import styled from "styled-components"
import { medWrapper, H3Black, B1Black, buttonThree } from "../../styles/helpers"

const FeaturedSection = styled.section`
  .wrapper {
    ${medWrapper};
  }

  .featuredPost {
    width: 100%;
    max-width: 80rem;
    margin: 2rem auto;

    &__title {
      margin-top: 2.5rem;

      h2 {
        ${H3Black};
      }
    }

    &__excerpt {
      @media (min-width: 1024px) {
        max-width: 90rem;
      }

      p {
        ${B1Black};
      }
    }

    &__link {
      a {
        ${buttonThree};
      }
    }
  }
`

const Featured = ({ data }) => {
  const { title, slug, acf } = data
  const imageFluid = acf._coo_post_feat_img.localFile.childImageSharp.fluid
  const imageAlt = acf._coo_post_feat_img.alt_text
  const experpt = acf._coo_post_excerpt

  return (
    <FeaturedSection>
      <div className="wrapper">
        <div className="featuredPost">
          <div className="featuredPost__image">
            <Img fluid={imageFluid} alt={imageAlt} />
          </div>
          <div className="featuredPost__title">
            <h2 dangerouslySetInnerHTML={{ __html: title }} />
          </div>
          <div
            className="featuredPost__excerpt"
            dangerouslySetInnerHTML={{ __html: experpt }}
          />
          <div className="featuredPost__link">
            <Link to={`/news/${slug}`}>Read More</Link>
          </div>
        </div>
      </div>
    </FeaturedSection>
  )
}

export default Featured
