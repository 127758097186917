import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

import Hero from "../components/News/Hero"
import Featured from "../components/News/Featured"
import PostsList from "../components/News/PostsList"

const News = props => {
  const {
    seoInfo,
    hero,
    featuredPost: { edges: fPost },
    posts: { edges: posts },
  } = props.data

  const featPost = fPost[0].node
  const postsList = posts.filter(post => {
    if (post.node.wordpress_id !== featPost.wordpress_id) return post
  })

  const location = props.location.pathname

  return (
    <Layout location={location}>
      <SEO
        title={seoInfo.acf._swb_theme_meta_title}
        description={seoInfo.acf._swb_theme_description}
        metaImg={seoInfo.acf._swb_theme_image.localFile.publicURL}
        location={props.location.pathname}
      />
      <Hero hero={hero} />
      <Featured data={featPost} />
      <PostsList data={postsList} />
    </Layout>
  )
}

export const newsQuery = graphql`
  query newsPage($id: Int!) {
    seoInfo: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
      }
    }

    hero: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_hohr_bg {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        _coo_hohr_title
        _coo_hohr_content
      }
    }

    featuredPost: allWordpressPost(
      filter: { categories: { eq: 6 } }
      limit: 1
    ) {
      edges {
        node {
          wordpress_id
          title
          slug
          date
          acf {
            _coo_post_excerpt
            _coo_post_post_content
            _coo_post_feat_img {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }

    posts: allWordpressPost {
      edges {
        node {
          wordpress_id
          title
          slug
          date
          acf {
            _coo_post_excerpt
            _coo_postpost_author
            _coo_post_feat_img {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default News
