import React, { useEffect, useState } from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import styled from "styled-components"
import moment from "moment"
import {
  medWrapper,
  colors,
  B1Black,
  fonts,
  fontSizer,
  H3Black,
  buttonThree,
} from "../../styles/helpers"
import { getMaxHeight } from "../../utils/helperFunc"
import SpinnerAnimation from "../QuickPossessions/SpinnerAnimation"

const PostsListSection = styled.section`
  .wrapper {
    ${medWrapper};
  }

  .moreLink {
    position: relative;
    width: 100%;
    margin: 5rem auto;
    text-align: center;
    z-index: 100;

    button {
      ${buttonThree};
    }
  }

  .singlePost {
    width: calc(100% - 1rem);
    margin: 3rem 0.5rem;
    background-color: ${colors.grey};
    transition: all 0.3s ease-out;
    transform: translateY(150px);
    box-shadow: 0rem 0rem 0rem 0rem rgba(0, 0, 0, 0.5);
    cursor: pointer;
    opacity: 0;

    @media (min-width: 768px) {
      width: calc(50% - 1rem);
      margin: 2rem 0.5rem;
    }

    @media (min-width: 1025px) {
      width: calc(33.33% - 4rem);
      margin: 2rem;
    }

    &.activePost {
      transform: translateY(0px);
      opacity: 1;
    }

    &:hover {
      box-shadow: 0.75rem 0.75rem 1.2rem 0.4rem rgba(0, 0, 0, 0.5);
    }

    &__header {
      padding: 3rem 3rem 2rem 3rem;
      border-bottom: solid 0.25rem ${colors.colorSecondary};

      h2 {
        ${H3Black};
        font-family: ${fonts.fontPrimary};
        font-weight: normal;
      }

      p {
        ${B1Black};
        ${fontSizer(1.3, 1.4, 76.8, 150, 1.6)};
        margin: 0;
      }
    }

    &__excerpt {
      padding: 1rem 3rem;

      p {
        ${B1Black};

        &:last-of-type {
          margin: 0;
        }
      }
    }

    &__link {
      padding: 1rem 3rem 3rem 3rem;

      a {
        @media (max-width: 767px) {
          ${buttonThree};
          padding-top: 1rem;
          padding-bottom: 1rem;
        }

        @media (min-width: 768px) {
          ${B1Black};
          ${fontSizer(1.2, 1.8, 76.8, 150, 1.6)}
          transition: all 0.3s ease;
          text-transform: uppercase;

          &:hover {
            color: ${colors.colorPrimary};
          }
        }

        span {
          display: none;
          padding-right: 0.5rem;

          @media (min-width: 768px) {
            display: inline-block;
          }
        }
      }
    }
  }
`

const LoadingModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 134, 117, 0.75);
  z-index: 999999;

  .innerLoading {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-self: center;
    background-color: ${colors.white};
    width: 40rem;
    margin: 0 auto;
    padding: 5rem 2rem;
    text-align: center;

    p {
      ${B1Black};
      margin: 0;
    }

    &__spinner {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-self: center;
      width: 100%;
      height: 3.5rem;
      margin: 0 auto;
    }
  }
`

const PostsList = ({ data }) => {
  const DISPLAY_NUMBER = 6
  const [postsStore, setPostsStoreStore] = useState({
    max: 0,
    current: 0,
    display: [],
    loading: false,
  })
  const [maxHeight, setMaxHeight] = useState(0)
  const posts = data

  useEffect(() => {
    setPostsStoreStore(prevState => {
      return {
        ...prevState,
        max: posts?.length,
        current: DISPLAY_NUMBER,
        display: posts.slice(0, DISPLAY_NUMBER),
        more: posts?.length > DISPLAY_NUMBER,
      }
    })
  }, [])

  useEffect(() => {
    const allCards = [...document.querySelectorAll(".singlePost")]
    const newMaxHeight = getMaxHeight(allCards)
    setMaxHeight(newMaxHeight)

    const addActiveClassToPostCard = () => {
      allCards.map(post => {
        post.classList.add("activePost")
        console.log(post)
      })
    }
    addActiveClassToPostCard()
  }, [postsStore])

  const getMorePosts = () => {
    setPostsStoreStore(prevState => {
      return {
        ...prevState,
        current: prevState.current + DISPLAY_NUMBER,
        display: posts.slice(0, prevState.current + DISPLAY_NUMBER),
        more: prevState.max > prevState.current + DISPLAY_NUMBER,
        loading: false,
      }
    })
  }

  const loadMorePostsHandler = () => {
    setPostsStoreStore(prevState => {
      return {
        ...prevState,
        loading: true,
      }
    })

    setTimeout(() => {
      getMorePosts()
    }, 2000)
  }

  return (
    <PostsListSection height={maxHeight}>
      <div className="wrapper">
        {postsStore.display.map(post => {
          return (
            <Link
              tto={`/news/${post.node.slug}`}
              className="singlePost"
              key={post.node.wordpress_id}
            >
              <div className="singlePost__image">
                <Img
                  fluid={
                    post.node.acf._coo_post_feat_img.localFile.childImageSharp
                      .fluid
                  }
                  alt={post.node.acf._coo_post_feat_img.alt_text}
                />
              </div>
              <div className="singlePost__header">
                <h2 dangerouslySetInnerHTML={{ __html: post.node.title }} />
                <p>Date: {moment(post.node.date).format("MMM Do, YYYY")}</p>
              </div>
              <div
                className="singlePost__excerpt"
                dangerouslySetInnerHTML={{
                  __html: post.node.acf._coo_post_excerpt,
                }}
              />
              <div className="singlePost__link">
                <Link to={`/news/${post.node.slug}`}>
                  <span>&gt;</span>Read More
                </Link>
              </div>
            </Link>
          )
        })}
        <div className="moreLink">
          <button
            disabled={!postsStore.more}
            onClick={loadMorePostsHandler}
            type="button"
          >
            {postsStore.more ? "LOAD MORE POSTS" : "NO MORE POSTS"}
          </button>
        </div>
      </div>
      {postsStore.loading && (
        <LoadingModal>
          <div className="innerLoading">
            <div className="innerLoading__spinner">
              <SpinnerAnimation />
            </div>
            <p>Loading more posts</p>
          </div>
        </LoadingModal>
      )}
    </PostsListSection>
  )
}

export default PostsList
